export function AccountFinder(){
    return {
        questions: QUESTIONS,
        messages:[],
        answers:[],
        history:[],
        showAnswers: true,
        init(){
            this.goto('q1258');
        },

        effect(){
            if(this.messages.length){
                this.$refs.messageContainer.scrollTo(0, this.$refs.messageContainer.scrollHeight);
                
            }
        },

        goto(question, reply){
            //Save question history
            this.history.push(question);
            //Clear out buttons for user feedback
            this.showAnswers = false;
            //simulate chat by sending answer as reply
            reply && this.messages.push(reply);
            question = this.questions[question];

            //If we're at the end of the questions we'll show links to products
            if(question.hasOwnProperty('cta') && question.cta.length){
                let message = 'You might be interested in ';
                question.cta.forEach(link => {
                    message += `<a href="${link.url}">${link.text}</a> `;
                });
                setTimeout(()=>{ this.messages.push(''); this.scroll() }, 500);
                setTimeout(()=>{ 
                    this.messages.splice(this.messages.length - 1, 1, message); 
                    this.scroll(); 
                    this.answers = question.a;
                    this.showAnswers = true;
                }, 1500);
                


            //Send next question
            } else{
                setTimeout(()=>{ this.messages.push(''); this.scroll() }, 500);
                setTimeout(()=>{ 
                    this.messages.splice(this.messages.length - 1, 1, question.q); 
                    this.scroll(); 
                    this.answers = question.a;
                    this.showAnswers = true;
                }, 1500);
                
            }

            
            this.$nextTick(()=> {  
                if(this.$root && this.$root.querySelector("button:focus")){
                    this.$root.querySelector("button:focus").blur();
                }      
            } );
            
        },

        scroll(){
            this.$nextTick(()=>this.$refs.messageContainer.scrollTo(0, this.$refs.messageContainer.scrollHeight))
        },


        goBack(){
            //pop off the current question
            let prev = this.history.pop();
            //pop off the last question so the user can re-answer it
            prev = this.history.pop();
            //go to the last question
            this.goto(prev, 'Go Back');
        }

        
    }

}